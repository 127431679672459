@font-face {
  font-family: 'Intelo-Bold';
  src: local('Intelo-ExtraBold'),
  url('./assets/fonts/Intelo-ExtraBold.ttf') format("truetype");
}
.tooltip-inner{
  max-width: 400px;
}

@font-face {
  font-family: 'Intelo-SemiBold';
  src: local('Intelo-SemiBold'),
  url('./assets/fonts/Intelo-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Intelo-Medium';
  src: local('Intelo-Medium'),
  url('./assets/fonts/Intelo-Medium.ttf') format("truetype");
}

@font-face {
  font-family: 'Intelo-Light';
  src: local('Intelo-Light'),
  url('./assets/fonts/Intelo-Light.ttf') format("truetype");
}

body {
  /* background-color :gray !important; */
  /* background-color: rgb(241, 243, 246) !important; */
  background-color: white !important;
  margin: 0;
}

.container-1 {
  background-color: "#FFF";
  /* margin-Top': 100  */
}

.form-control::placeholder {
  color: #c1c1c1 !important;
  opacity: 1 !important;
}

.border-t {
  border-top: 1px solid #dee2e6;
}
.border-r {
  border-right: 1px solid #dee2e6;
}
.border-b {
  border-bottom: 1px solid #dee2e6;
}
.border-l {
  border-left: 1px solid #dee2e6;
}

.rbt-aux .btn-close {
  pointer-events: auto;
}

.form-control:focus{
 
  box-shadow: none;
}
.btn{
  border:none;
}

